<template>
  <!-- TODO: Vuetify -->
  <v-card width="100%">
    <v-card-title>
      Dateibrowser
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Suche"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      fixed-header
      :headers="headers"
      :items="media"
      :search="search"
      :items-per-page="15"
      :footer-props="{
        'items-per-page-text': 'Artikel pro Seite',
        'items-per-page-options': [15, 25, 50, -1]
      }"
      @click:row="showAlert"
    >
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "Media",
  data() {
    return {
      media: [],
      search: "",
      headers: [{ text: "Dateiname", value: "item.filePath" }]
    };
  },
  methods: {
    showAlert(a) {
      alert("Alert! \n" + a.name);
    },
    async loadMedia() {
      await axios
        .get(`${process.env.VUE_APP_API_URL}/media_objects.json`)
        .then(response => {
          this.media = response.data.data;
        });
    }
  },
  mounted() {
    this.loadMedia();
  }
};
</script>

<style lang="scss">
tbody {
  tr {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
